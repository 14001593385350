import _ from 'lodash'
import { ImageNode } from '~src/types/graphql-types'

export const findImages = (
  data,
  imageNames: string[]
): { node: ImageNode }[] => {
  return imageNames.map((name: string) =>
    _.find(
      data.allFile.edges,
      (sharpNode: any): boolean => sharpNode.node.name === name
    )
  )
}

export const langIndex = (lang: string): number => (lang === 'PL' ? 0 : 1)
